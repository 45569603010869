import consumer_t_od_hotel_order_interim_report_list from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_report_list';
import consumer_t_od_hotel_order_interim_report_excel from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_report_excel';

export default {
    data() {
        return {
            form: {
                orderNo: '',
                completionDate: '',
                paymentTime: '',
                companyName: '',
                checkInDate: '',
                checkOutDate: '',
                productName: '',
                currentPage: 1,
                pageSize: 10,
            },
            loading: false,
            tableData: [],
            totalCount: 0,
        }
    },
    methods: {
        setTableData() {
            const body = this.formatBody();
            this.loading = true;
            consumer_t_od_hotel_order_interim_report_list(body)
                .then(data => {
                    this.totalCount = data.datas.totalCount;
                    this.tableData = data.datas.pageData;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onReset() {
            this.form = {
                orderNo: '',
                completionDate: '',
                paymentTime: '',
                companyName: '',
                checkInDate: '',
                checkOutDate: '',
                productName: '',
                currentPage: 1,
                pageSize: 10,
            };
            this.setTableData();
        },
        onExport() {
            let body = this.formatBody();
            delete body.pageSize;
            delete body.currentPage;
            this.exportLoading = true;
            consumer_t_od_hotel_order_interim_report_excel(body)
                .then(data => {
                    window.open(`http://${data.datas}`, '_blank');
                })
                .finally(() => {
                    this.exportLoading = false;
                });
        },
        handleCurrentChange(page) {
            this.form.currentPage = page;
            this.setTableData();
        },
        handleSizeChange(size) {
            this.form.currentPage = 1;
            this.form.pageSize = size;
            this.setTableData();
        },
        formatBody() {
            const {
                completionDate,
                paymentTime,
                checkInDate,
                checkOutDate,
            } = this.form;
            const body = Object.assign({}, this.form, {
                completionDate: completionDate ? this.$moment(completionDate).format('YYYY-MM-DD') : '',
                paymentTime: paymentTime ? this.$moment(paymentTime).format('YYYY-MM-DD') : '',
                checkInDate: checkInDate ? this.$moment(checkInDate).format('YYYY-MM-DD') : '',
                checkOutDate: checkOutDate ? this.$moment(checkOutDate).format('YYYY-MM-DD') : '',
            });
            return body;
        },
        formatBuyerAmount(buyerAmount) {
            return `￥${buyerAmount.toFixed(2)}`;
        },
        formatBuyerPaymentTime(buyerPaymentTime) {
            return buyerPaymentTime ? this.$moment(buyerPaymentTime).format('YYYY/MM/DD hh:mm:ss') : '--';
        },
    },
    created() {
        this.setTableData();
    },
    activated() { },
}