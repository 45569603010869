// consumer_t_od_hotel_order_interim_report_excel 平台运营报表列表
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.haolvtrip.com/doc.html#/tmc-biz/酒店订单/reportListUsingPOST
const request = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/t-od-hotel-order-interim/report/excel',
    data: pParameter
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = request;